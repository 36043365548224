.herocard-container {
	display: flex;
	justify-content: space-evenly;
	padding: 50px;
	min-height: 300px;
	flex-wrap: wrap;
	row-gap: 50px;
}

.hero-multi-text {
	max-width: 460px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.hero-image {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
