@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");

:root {
	--primary: #00e575;
	--primary-light: #66ffa5;
	--primary-pale: #d5ffe6;
	--primary-dark: #30b380;
	--primary-text: #fafafa;
	--secondary: #ced7db;
	--secondary-light: #eee;
	--secondary-pale: #ccc;
	--secondary-dark: #5d6061;
	--secondary-text: #000000;

	--xs: 4px;
	--s: 8px;
	--m: 16px;
	--l: 24px;
	--xl: 32px;
}

body {
	margin: 0;
	padding: 0;
	font-family: "Barlow", sans-serif;
	font-weight: 300;
	color: black;
}

body p {
	margin: 0.5em 0px;
}

body h1 {
	font-weight: 600;
	font-size: 44px;
	margin: var(--m) 0px;
	line-height: 1;
	color: inherit;
}

body h2 {
	margin: var(--s) 0px;
	font-size: 24px;
	font-weight: 600;
	color: inherit;
}

body h3 {
	margin: var(--s) 0px;
	font-size: 22px;
	font-weight: 500;
	color: inherit;
}

.u-text-hover-primary:hover {
	text-shadow: 0 0 4px var(--primary-text);
}

.u-pointer {
	cursor: pointer;
}

.u-logo {
	color: var(--primary);
	font-weight: 500;
	display: inline-block;
}

.u-flex {
	display: flex;
}

.u-flex-column {
	display: flex;
	flex-direction: column;
}

.u-justify-start {
	justify-content: flex-start;
}

.u-center {
	justify-content: center;
}

.u-align-center {
	align-content: center;
}

.u-column {
	flex-direction: column;
}

.u-block {
	display: block;
}

body table {
	border-collapse: collapse;
}

body th {
	background: var(--primary);
	color: var(--primary-text);
	font-size: 16px;
	border: none;
	padding: 2px 3px;
}

body td {
	border-bottom: 1px solid grey;
	text-align: center;
}

body th {
	border-bottom: 1px solid var(--primary);
}

input {
	font-family: "Barlow", sans-serif;
}

input[type="text"] {
	font-size: 16px;
	padding: 4px;
	border: none;
	border-bottom: 2px solid var(--secondary);
	border-radius: 2px;
	background-color: #eee;
}

input[type="text"]:hover {
	background-color: #ddd;
}

input[type="text"]:hover {
	background-color: #efefef;
}

input[type="text"]:focus {
	border: none;
	border-radius: 2px;
	outline: none;
	border-bottom: 2px solid var(--primary);
	background-color: var(--primary-pale);
}

input:focus::placeholder {
	color: transparent;
}

.material-icons {
	font-size: 1em;
}

.material-icons.text-button {
	color: var(--secondary);
	cursor: copy;
	user-select: none;
}

.material-icons.text-button:hover {
	color: var(--secondary-text);
	background-color: #eee;
	border-radius: 0.1em;
}

.material-icons.text-button:active {
	color: var(--primary);
}

.u-gap-10 {
	gap: 10px;
}

.u-primary-gradient-bg {
	background-image: linear-gradient(var(--primary), var(--primary-dark));
	color: white;
}

.u-white-bg {
	background-color: white;
	color: black;
}

.u-secondary-gradient-bg {
	background-image: linear-gradient(
		var(--secondary-light),
		var(--secondary-pale)
	);
	color: black;
}
