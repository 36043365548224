.navbar-container {
	padding: var(--m);
	display: flex;
	align-items: center;
	-webkit-position: sticky;
	position: sticky;
	top: 0;
	background-color: white;
	align-self: flex-start;
	border-bottom: 2px solid var(--primary);
	z-index: 1;
}

.navbar-logo {
	font-size: 1.5em;
	font-weight: 400;
	color: var(--primary);
	letter-spacing: -0.5px;
	user-select: none;
	transition: color 0.15s ease-out;
}

.navbar-logo:hover {
	color: var(--primary-dark);
	cursor: pointer;
}

.navbar-logo a {
	text-decoration: none;
	border: none;
}

.navbar-buttons {
	margin-left: auto;
	display: flex;
	align-items: center;
}

.navbar-buttons a {
	color: var(--secondary-dark);
	font-weight: 400;
	text-decoration: none;
	transition: color 0.15s ease-out;
	margin: 0px var(--s);
}

.navbar-buttons a:hover {
	color: var(--primary);
	text-decoration: none;
	border: none;
}
