.privacy-statement {
	font-size: 20px;
	max-width: 1024px;
	text-align: justify;
}

.privacy-statement h3 {
	margin-top: 24px;
	margin-bottom: 12px;
}
