code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

#root {
	background-color: --var(primary);
	color: black;
	font-size: 24px;
}

html,
body {
	width: auto;
}
