a {
	color: var(--primary);
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

a:hover {
	color: var(--primary-dark);
	border-bottom: 1px dotted var(--primary-dark);
}

a:active {
	color: var(--primary);
	border-bottom: 1px solid var(--primary);
}

.app-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: auto;
}

.hero-container {
	display: flex;
	flex-flow: column;
	align-items: center;
	width: 100%;
}

.hero-name {
	font-size: 6em;
	color: white;
	font-weight: 500;
	letter-spacing: -0.03em;
	animation: slideInFromTop 1s ease-in-out;
}

@keyframes slideInFromTop {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}

.hero-tagline {
	flex: 0 1 auto;
	font-size: 1.5em;
	animation: fadeIn ease 2s;
}

.hero-content {
	margin-top: 2em;
	animation: waitThenFade ease 3s;
}

.hero-cards {
	width: 100%;
}

.coming-soon {
	text-align: center;
}

.register-container {
	display: flex;
	justify-content: space-between;
	margin-top: 0.5em;
	margin-bottom: 0.2em;
	font-size: 18px;
	width: 100%;
	align-items: center;
	position: relative;
}

.footer-container {
	margin-top: 1em;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes waitThenFade {
	0% {
		opacity: 0;
	}
	66% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.register-result {
	font-size: 17px;
	font-weight: 500;
	border-radius: 2px;
	border: 2px solid transparent;
	padding: 4px;
	background-color: #d30;
	visibility: hidden;
	position: absolute;
	bottom: -36px;
}

.register-result.success {
	background-color: rgb(184, 255, 200);
	color: rgb(16, 168, 29);
	border: 1px solid rgb(16, 168, 29);
	border-radius: 2px;
}

.register-result.warning {
	background-color: rgb(255, 237, 137);
	color: rgb(168, 115, 16);
	border: 1px solid rgb(168, 115, 16);
	border-radius: 2px;
}

.register-result.invalid {
	background-color: rgb(255, 179, 156);
	color: rgb(223, 25, 25);
	border: 1px solid rgb(214, 0, 0);
	border-radius: 2px;
}

.fade-out-2s {
	animation: fadeOut 2s ease-in-out;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.hero-footer {
	display: flex;
	font-size: 16px;
	min-height: 60px;
	width: 100%;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	color: black;
}

.u-deepdive-font {
	color: var(--primary-dark);
	font-weight: 500;
}

.coming-soon {
	max-width: 700px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0.3em 0.5em;
	border-radius: 0.5em;
	background-image: linear-gradient(var(--primary-light), var(--primary-dark));
	color: white;
}

.coming-soon h1 {
	font-size: 3em;
	margin-bottom: 0.5em;
	color: var(--primary-dark);
	color: white;
}

.coming-soon a {
	color: white;
	border-bottom: 1px dotted white;
}

.coming-soon a:hover {
	color: var(--secondary-light);
}

.register-bottom {
	margin-top: 1em;
	margin-bottom: 2em;
	max-width: 500px;
}

.hero-image-source {
	width: 280px;
}

.hero-image-outputs {
	width: 300px;
}

@media (max-width: 768px) {
	.hero-image-source {
		width: 180px;
	}

	.hero-image-outputs {
		width: 200px;
	}
}
