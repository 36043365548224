input[type="text"] {
	font-size: 24px;
	padding: 4px;
	border: none;
	border-bottom: 2px solid var(--secondary);
}

input[type="text"]:hover {
	background-color: var(--primary-pale);
}

input[type="text"]:focus {
	border: none;
	outline: none;
	border: 2px transparent;
	border-bottom: 2px solid var(--primary-dark);
}

input[type="email"] {
	padding: 0px 4px;
	border: 2px solid var(--secondary);
	border-radius: 5px;
	font-size: inherit;
	width: 100%;
	height: 100%;
}

input[type="email"]:hover {
	background-color: var(--secondary-light);
}

input[type="email"]:focus {
	border: none;
	outline: none;
	border: 2px solid var(--secondary);
}

.input-container {
	flex-grow: 1;
	height: 100%;
}

input:focus::placeholder {
	color: transparent;
}

.button-input {
	font-family: Barlow, sans-serif;
	user-select: none;
	cursor: pointer;
	background-color: var(--primary);
	border-radius: 5px;
	border: none;
	font-size: inherit;
	color: white;
	padding: var(--s) var(--m);
	transition: background-color 0.2s ease-in-out;
	margin: 0px var(--m);
	flex-grow: 0;
}

.button-input:hover {
	background-color: var(--primary-dark);
}
